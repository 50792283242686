import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import Slider from 'react-rangeslider';
import RotateLoader from 'react-spinners/RotateLoader';
import { useTranslation } from 'react-i18next';

import 'react-rangeslider/lib/index.css';

import Button from '../Button';
import { getCroppedImg, getReducedImage } from '../../utils/canvas';

import MaskShape from '../../images/publikfigur-mask.svg';

import { breakpoint } from '../../theme';

const ZOOM_MIN = 0.5;
const ZOOM_MAX = 4;

const IMAGE_WIDTH = 984;
const IMAGE_HEIGHT = 1673;

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

async function getSession(organization) {
  const session = await fetch(`${process.env.API_URL}/session`, {
    method: 'POST',
    body: JSON.stringify({ organization }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return session.json();
}

const Root = styled.div`
  color: #000;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CropWrap = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-content: center;
`;

const CropContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;

  & .reactEasyCrop_CropArea {
    /* background-image: url(${MaskShape}); */
    background-image: url(${({ customShape }) => customShape || MaskShape});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;
  }
`;

const CropArea = styled(Cropper)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const HelperText = styled.div`
  text-align: center;
  font-size: ${rem(14)};
`;

const CheckoutButton = styled(Button)`
  font-size: ${rem(13)};
  padding: 0.95em 1.25em;

  ${breakpoint('large')} {
    font-size: ${rem(15)};
  }
`;

const Controls = styled.div`
  flex-grow: 1;
  padding: 1rem;
  display: none;
  background-color: ${(props) => props.theme.palette.colors.white};
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 40%;
    display: block;
  }

  ${breakpoint('large')} {
    width: 47%;
  }
`;

const UploadLabel = styled.label`
  display: block;
  position: relative;
  text-align: center;
`;

const UploadField = styled.input`
  position: fixed;
  top: 0px;
  left: -1000px;
  height: 0;
  width: 0;
  overflow: hidden;
`;

const ControlsInner = styled.div`
  max-width: 18rem;
  margin: auto;
`;

const ControlRow = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(30)};
`;

const ControlLabel = styled.h5`
  font-size: ${rem(18)};
  margin: 0;
`;

const Actions = styled.div`
  z-index: 99;
  padding: 0.8rem ${(props) => props.theme.spacing.columnGutter};
  background-color: ${(props) => rgba(props.theme.palette.colors.black, 0.9)};
  flex-shrink: 0;

  ${breakpoint('large')} {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }
`;

const ActionsInner = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.grid.maxWidthText};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
`;

const ErrorMessage = styled.div`
  font-size: ${rem(14)};
  background-color: ${(props) => props.theme.palette.colors.alert};
  color: #fff;
  padding: 0.5em 1em;
  border-radius: 7px;
  margin-bottom: 1rem;
`;

const UploadArea = styled.div`
  padding: 0 ${(props) => props.theme.spacing.columnGutter} ${rem(80)};
`;

const LoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => rgba(props.theme.palette.colors.black, 0.6)};
`;

const LoadingText = styled.h6`
  color: ${(props) => props.theme.palette.colors.white};
  margin-top: 36px;
  text-align: center;
`;

function Editor({ teamId, teamLogo, setIsEditing, mask }) {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const setEditing = (status) =>
    typeof setIsEditing === 'function' && setIsEditing(status);

  const onCropComplete = useCallback((area, areaPixels) => {
    setCroppedAreaPixels(areaPixels);
  }, []);

  const onReset = useCallback(() => {
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);
    setEditing(false);
  }, []);

  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await getReducedImage(e.target.files[0]);

      setImageSrc(imageDataUrl);
      setEditing(true);
    }
  }, []);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const [croppedImage, session, stripe] = await Promise.all([
        getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          { width: IMAGE_WIDTH, height: IMAGE_HEIGHT },
          teamLogo,
          rotation,
        ),
        getSession(teamId),
        stripePromise,
      ]);

      if (!session.upload?.fields) {
        throw new Error('Unable to create session');
      }

      const formData = new FormData();

      Object.entries(session.upload.fields).forEach(([name, value]) => {
        formData.append(name, value);
      });

      formData.append('success_action_status', '201');
      formData.append('Content-Type', 'image/jpeg');
      formData.append('file', croppedImage);

      const uploadResults = await fetch(session.upload.url, {
        method: 'POST',
        body: formData,
      });

      if (uploadResults.status !== 201) {
        throw new Error('Upload failed');
      }

      const redirect = await stripe.redirectToCheckout({
        sessionId: session.checkout.id,
      });

      if (redirect.error) {
        throw new Error(redirect.error.message);
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
      console.error(err); // eslint-disable-line no-console
    }
  }, [imageSrc, croppedAreaPixels, rotation, teamId]);

  if (!imageSrc) {
    return (
      <UploadArea>
        <UploadLabel htmlFor="fileUpload">
          <Button as="div" className="cta">
            {t('uploadImage')}
          </Button>
        </UploadLabel>
        <HelperText>
          <p>{t('uploadImageHelper')}</p>
        </HelperText>
        <UploadField
          id="fileUpload"
          type="file"
          onChange={onFileChange}
          accept="image/*"
        />
      </UploadArea>
    );
  }

  return (
    <Root>
      <CropWrap>
        <CropContainer customShape={mask}>
          <CropArea
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={IMAGE_WIDTH / IMAGE_HEIGHT}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
            showGrid={false}
            minZoom={ZOOM_MIN}
            maxZoom={ZOOM_MAX}
            zoomWithScroll={false}
          />
        </CropContainer>
        <Controls>
          <ControlsInner>
            <ControlRow>
              <ControlLabel htmlFor="zoom">{t('size')}</ControlLabel>
              <Slider
                min={ZOOM_MIN}
                max={ZOOM_MAX}
                step={0.1}
                value={zoom}
                id="zoom"
                tooltip={false}
                onChange={(value) => setZoom(value)}
              />
            </ControlRow>
            <ControlRow>
              <ControlLabel htmlFor="rotation">{t('rotation')}</ControlLabel>
              <Slider
                max={180}
                min={-180}
                step={15}
                value={rotation}
                id="rotation"
                tooltip={false}
                onChange={(value) => setRotation(value)}
              />
            </ControlRow>
          </ControlsInner>
        </Controls>
      </CropWrap>
      {loading && (
        <LoadingOverlay>
          <RotateLoader color="#fff" size={14} margin={0} />
          <LoadingText>{t('processing')}</LoadingText>
        </LoadingOverlay>
      )}
      <Actions>
        {error && (
          <ErrorMessage>
            <strong>{t('errorTitle')} </strong>
            {error}
          </ErrorMessage>
        )}
        <ActionsInner>
          <CheckoutButton className="grey" onClick={onReset}>
            {t('reset')}
          </CheckoutButton>
          <CheckoutButton
            className="cta"
            onClick={onSubmit}
            disabled={loading}
            style={{ marginLeft: '1rem' }}
          >
            {loading ? t('loading') : t('toPayment')}
          </CheckoutButton>
        </ActionsInner>
      </Actions>
    </Root>
  );
}

Editor.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamLogo: PropTypes.string.isRequired,
  setIsEditing: PropTypes.func,
  mask: PropTypes.string,
};

Editor.defaultProps = {
  setIsEditing: undefined,
  mask: undefined,
};

export default Editor;
