/**
 * Taken from official example from react-easy-crop (https://github.com/ricardo-ch/react-easy-crop):
 * https://codesandbox.io/s/react-easy-crop-custom-image-demo-y09komm059?file=/src/canvasUtils.js
 */

import ImageReducer from 'image-blob-reduce';
import Pica from 'pica';

const reducer = new ImageReducer({
  pica: new Pica(),
});

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  outputSize,
  watermarkSrc,
  rotation = 0,
) {
  const image = await createImage(imageSrc);
  const watermark = await createImage(watermarkSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  const scale = Math.max(
    outputSize.width / pixelCrop.width,
    outputSize.height / pixelCrop.height,
  );
  const scaledCanvas = document.createElement('canvas');
  const scaledCtx = scaledCanvas.getContext('2d');

  scaledCanvas.width = outputSize.width;
  scaledCanvas.height = outputSize.height;

  scaledCtx.drawImage(
    canvas,
    0,
    0,
    canvas.width * scale,
    canvas.height * scale,
  );

  scaledCtx.drawImage(
    watermark,
    scaledCanvas.width / 2 - watermark.width / 2,
    scaledCanvas.height - watermark.height * 1.5,
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    scaledCanvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg');
  });
}

export async function getReducedImage(image) {
  const rotatedImage = await reducer.toBlob(image, {
    max: 2600,
    unsharpAmount: 80,
    unsharpRadius: 0.6,
    unsharpThreshold: 2,
  });

  return URL.createObjectURL(rotatedImage);
}
