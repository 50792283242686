/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import { useTranslation, Trans } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Editor from '../components/Editor';
import Button from '../components/Button';

import shl21Shape from '../data/images/mask-shl21.svg';

import { breakpoint } from '../theme';

const productPrice = 300;

const masks = {
  shl21: shl21Shape,
};

const View = styled.div`
  ${({ fullscreen }) =>
    fullscreen &&
    css`
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    `};
`;

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(20)} auto 0;

  ${breakpoint('large')} {
    margin-top: ${rem(20)};
  }
`;

const EditorWrap = styled.div`
  margin: 0 auto;
  height: 100%;
`;

const PageTitle = styled.h1`
  line-height: 1.2em;
  font-size: ${rem(20)};

  ${breakpoint('large')} {
    font-size: ${rem(26)};
  }
`;

const TeamLogo = styled.div`
  width: ${rem(70)};
  max-width: 100%;
  margin: ${rem(-20)} auto ${rem(20)};

  ${breakpoint('large')} {
    margin: 0 auto ${rem(25)};
    width: ${rem(115)};
  }
`;

const Price = styled.h5`
  margin-top: 1rem;
  font-size: ${rem(24)};

  ${breakpoint('large')} {
    font-size: ${rem(28)};
  }
`;

const PriceAccent = styled.span`
  color: ${(props) => props.theme.palette.colors.cta};
`;

const PriceNote = styled.span`
  display: block;
  margin-top: 0.5rem;
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const Modal = styled.aside`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: ${(props) => rgba(props.theme.palette.colors.black, 0.75)};
  padding: ${(props) => props.theme.spacing.gutter};
`;

const ModalContent = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 42rem;
  margin: auto;

  background-color: ${(props) => props.theme.palette.colors.white};
  border-radius: 10px;
  padding: ${(props) => props.theme.spacing.gutter};
`;

const ModalInner = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  color: #000;
  font-size: 0.95rem;
  text-align: center;
`;

const MobileHelperText = styled.span`
  ${breakpoint('mediumlarge')} {
    display: none;
  }
`;

const Body = styled.div`
  margin: 1rem 0;
`;

function Team({ data }) {
  const { t } = useTranslation();

  const [imageSelected, setImageSelected] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);

  const { teamsJson: team } = data;
  const { active } = team;

  // useLangRedirect(team);

  if (!team) {
    return null;
  }

  return (
    <Layout
      headerProps={{ backgroundColor: team.color }}
      withHeader={!imageSelected}
      withFooter={!imageSelected}
    >
      <SEO title={t('teamSeoTitle', { team: team.title })} />
      <View fullscreen={imageSelected}>
        {!imageSelected && (
          <Hero tint image={team?.hero}>
            <>
              <TeamLogo>
                <Image
                  fluid={team.logo.childImageSharp.fluid}
                  alt={`${team.title} logo`}
                />
              </TeamLogo>
              <PageTitle>{t('teamTitle', { team: team.title })}</PageTitle>
              <p style={{ fontSize: '1rem' }}>
                <Trans
                  i18nKey="teamDesc"
                  values={{ team: team.title, venue: team.venue }}
                />
              </p>
              {team.body && (
                <Body
                  dangerouslySetInnerHTML={{ __html: team.body }} // eslint-disable-line react/no-danger
                />
              )}
              <Price>
                {t('teamPrice')} <PriceAccent>{productPrice} kr</PriceAccent>
                <PriceNote>
                  {t('teamComission', {
                    comission: productPrice / 2,
                    type: team.league === 'event' ? t('organizer') : t('club'),
                  })}
                </PriceNote>
              </Price>
            </>
          </Hero>
        )}

        {active ? (
          <>
            {!imageSelected && (
              <Container>
                <PageTitle
                  style={{ textAlign: 'center', marginBottom: '1.6rem' }}
                >
                  {t('teamCtaTitle')}
                </PageTitle>
              </Container>
            )}

            <EditorWrap>
              <Editor
                mask={masks?.[team.mask]}
                teamId={team.id}
                teamLogo={team.logo.childImageSharp.fixed.src}
                setIsEditing={setImageSelected}
              />
            </EditorWrap>
          </>
        ) : (
          <Container style={{ textAlign: 'center' }}>
            <PageTitle>{t('teamInactiveTitle')}</PageTitle>
            <p>
              <Trans i18nKey="teamInactiveText" values={{ team: team.title }} />
            </p>
          </Container>
        )}

        {modalVisible && imageSelected && (
          <Modal>
            <ModalContent>
              <ModalInner>
                <h6>{t('teamModalStep', { current: 2, total: 2 })}</h6>
                <PageTitle>{t('teamModalTitle')}</PageTitle>
                <p>
                  {t('teamModalDesc')}
                  <br />
                  <MobileHelperText>
                    {t('teamModalDescMobild')}
                  </MobileHelperText>
                </p>
              </ModalInner>
              <Button
                onClick={() => setModalVisible(false)}
                className="cta small"
                style={{ width: '100%' }}
              >
                {t('continue')}
              </Button>
            </ModalContent>
          </Modal>
        )}
      </View>
    </Layout>
  );
}

Team.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    teamsJson(slug: { eq: $slug }) {
      id
      active
      title
      body
      logo {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          fixed(
            width: 120
            height: 120
            fit: CONTAIN
            background: "transparent"
          ) {
            src
          }
        }
      }
      hero {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      venue
      league
      color
      mask
    }
  }
`;

export default Team;
